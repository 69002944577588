import { z } from 'zod'

export const setZodErrorMap = (local: 'en' | 'ja') => {
    switch (local) {
        case 'en':
            // empty
            break
        case 'ja':
            z.setErrorMap(jaZodErrorMap)
    }
}

const jaZodErrorMap: z.ZodErrorMap = (issue, ctx) => {
    switch (issue.code) {
        case z.ZodIssueCode.invalid_type:
            if (issue.received === 'undefined') {
                return {
                    message: '必須',
                }
            } else {
                if (issue.expected === 'number') {
                    return {
                        message: `数字で入力してください`,
                    }
                }
                return {
                    message: `Expected ${issue.expected}, received ${issue.received}`,
                }
            }
        case z.ZodIssueCode.unrecognized_keys:
            return {
                message: `Unrecognized key(s) in object: ${issue.keys
                    .map((k) => `'${k}'`)
                    .join(', ')}`,
            }
        case z.ZodIssueCode.invalid_union:
            return {
                message: `Invalid input`,
            }
        case z.ZodIssueCode.invalid_union_discriminator:
            return {
                message: `Invalid discriminator value. Expected ${issue.options
                    .map((val) => (typeof val === 'string' ? `'${val}'` : val))
                    .join(' | ')}`,
            }
        case z.ZodIssueCode.invalid_enum_value:
            return {
                message: `Invalid enum value. Expected ${issue.options
                    .map((val) => (typeof val === 'string' ? `'${val}'` : val))
                    .join(' | ')}`,
            }
        case z.ZodIssueCode.invalid_arguments:
            return {
                message: `Invalid function arguments`,
            }
        case z.ZodIssueCode.invalid_return_type:
            return {
                message: `Invalid function return type`,
            }
        case z.ZodIssueCode.invalid_date:
            return {
                message: `無効な日付です。※月は2桁で入れてください。例: 03`,
            }
        case z.ZodIssueCode.invalid_string:
            if (issue.validation !== 'regex') {
                return {
                    message: `${issue.validation}は無効な形式です`,
                }
            } else {
                return {
                    message: 'Invalid',
                }
            }
        case z.ZodIssueCode.too_small:
            if (issue.type === 'array') {
                return {
                    message: `Array must contain ${issue.inclusive ? `at least` : `more than`} ${
                        issue.minimum
                    } element(s)`,
                }
            } else if (issue.type === 'string') {
                if (issue.minimum === 1) {
                    return {
                        message: '必須入力',
                    }
                }
                return {
                    message: issue.inclusive
                        ? `文字列には少なくとも${issue.minimum}文字が含まれている必要があります`
                        : `文字列には${issue.minimum}文字以上が含まれている必要があります`,
                }
            } else if (issue.type === 'number') {
                return {
                    message: `${issue.minimum.toLocaleString()} ${
                        issue.inclusive ? `以上の` : `より大きい`
                    }値を指定してください`,
                }
            } else {
                return { message: '無効な文字列です' }
            }
        case z.ZodIssueCode.too_big:
            if (issue.type === 'array') {
                return {
                    message: `Array must contain ${issue.inclusive ? `at most` : `less than`} ${
                        issue.maximum
                    } element(s)`,
                }
            } else if (issue.type === 'string') {
                return {
                    message: `String must contain ${issue.inclusive ? `at most` : `under`} ${
                        issue.maximum
                    } character(s)`,
                }
            } else if (issue.type === 'number') {
                return {
                    message: `${issue.maximum.toLocaleString()} ${
                        issue.inclusive ? `以下` : `未満`
                    }の値を指定してください`,
                }
            } else {
                return {
                    message: 'Invalid input',
                }
            }
        case z.ZodIssueCode.custom:
            return {
                message: `Invalid input`,
            }
        case z.ZodIssueCode.invalid_intersection_types:
            return {
                message: `Intersection results could not be merged`,
            }
        case z.ZodIssueCode.not_multiple_of:
            return {
                message: `Number must be a multiple of ${issue.multipleOf}`,
            }
        default:
            return { message: ctx.defaultError }
    }
}
