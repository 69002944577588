import {
    AccountBalance,
    Apartment,
    Dashboard,
    Group,
    GroupOutlined,
    Inventory,
    Launch,
    Logout,
    MenuRounded,
    Notifications,
    OpenInNew,
    Payment,
    Work,
    WorkHistory,
} from '@mui/icons-material'

import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List as MuiList,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material'
import {
    CanAccess,
    useIsExistAuthentication,
    useLogout,
    useMenu,
    useRefineContext,
    useRouterContext,
} from '@refinedev/core'

import { Title as DefaultTitle } from '@refinedev/mui'
import { useRouter } from 'next/router'

import React, { useCallback } from 'react'
import { Title } from '../layout/title'

export const Sider: React.FC = () => {
    const router = useRouter()

    const drawerWidth = 72 // px

    const { Link } = useRouterContext()
    const { hasDashboard } = useRefineContext()

    const { selectedKey } = useMenu()

    const isExistAuthentication = useIsExistAuthentication()
    const { mutate: mutateLogout } = useLogout()

    const RenderToTitle = React.useMemo(() => Title ?? DefaultTitle, [])

    const dashboard = React.useMemo(
        () =>
            hasDashboard ? (
                <CanAccess resource="dashboard" action="list">
                    <Tooltip
                        title="ダッシュボード"
                        placement="right"
                        disableHoverListener={false}
                        arrow
                    >
                        <ListItemButton
                            component={Link}
                            to="/"
                            selected={selectedKey === '/'}
                            sx={{
                                pl: 2,
                                py: 1,
                                '&.Mui-selected': {
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    backgroundColor: 'transparent',
                                },
                                justifyContent: 'center',
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    justifyContent: 'center',
                                    minWidth: 36,
                                    color: 'primary.contrastText',
                                }}
                            >
                                <Dashboard />
                            </ListItemIcon>
                            <ListItemText
                                primary="ダッシュボード"
                                primaryTypographyProps={{
                                    noWrap: true,
                                    fontSize: '14px',
                                    fontWeight: selectedKey === '/' ? 'bold' : 'normal',
                                }}
                            />
                        </ListItemButton>
                    </Tooltip>
                </CanAccess>
            ) : null,
        [Link, hasDashboard, selectedKey]
    )

    const logout = React.useMemo(
        () =>
            isExistAuthentication && (
                <Tooltip title="ログアウト" placement="right" disableHoverListener={false} arrow>
                    <ListItemButton
                        key="logout"
                        onClick={() => mutateLogout()}
                        sx={{ justifyContent: 'center', paddingY: 0.5 }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    justifyContent: 'center',
                                    minWidth: 36,
                                    color: 'primary.contrastText',
                                }}
                            >
                                <Logout />
                            </ListItemIcon>
                            <ListItemText
                                primary="ログアウト"
                                primaryTypographyProps={{
                                    noWrap: true,
                                    fontSize: '8px',
                                }}
                            />
                        </Box>
                    </ListItemButton>
                </Tooltip>
            ),
        [isExistAuthentication, mutateLogout]
    )

    const createMenu = useCallback(
        (menu: { key: string; path: string; title: string; icon: React.ReactElement }[]) => {
            return (
                <>
                    {menu.map(({ key, path, title, icon }) => (
                        <Tooltip
                            key={key}
                            title={title}
                            placement="right"
                            disableHoverListener={false}
                            arrow
                        >
                            <ListItemButton
                                key={key}
                                onClick={() => router.push(path)}
                                sx={{ justifyContent: 'center', paddingY: 0.5 }}
                                selected={selectedKey === path}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            justifyContent: 'center',
                                            minWidth: 36,
                                            color: 'primary.contrastText',
                                        }}
                                    >
                                        {icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={title}
                                        primaryTypographyProps={{
                                            fontSize: '8px',
                                            fontWeight: selectedKey === path ? 'bold' : 'normal',
                                        }}
                                    />
                                </Box>
                            </ListItemButton>
                        </Tooltip>
                    ))}
                </>
            )
        },
        [router, selectedKey]
    )

    const menuLink = React.useMemo(() => {
        const menu = [
            {
                key: 'app_group',
                path: '/app_group',
                title: 'グループ(法人・個人事業主)',
                icon: <Group />,
            },
            {
                key: 'company_verification',
                path: '/company_verification',
                title: '法人確認',
                icon: <Apartment />,
            },
            { key: 'app_user', path: '/app_user', title: 'ユーザー', icon: <GroupOutlined /> },
            { key: 'task', path: '/task', title: '仕事', icon: <Work /> },
            {
                key: 'task_client_field',
                path: '/task_client_field',
                title: '作業日数',
                icon: <WorkHistory />,
            },
            {
                key: 'admin_notification',
                path: '/admin_notification',
                title: '通知',
                icon: <Notifications />,
            },
        ]

        return createMenu(menu)
    }, [createMenu])

    const organizerLink = React.useMemo(() => {
        const menu = [
            {
                key: 'organizer_group_can_create_email',
                path: '/organizer_group_can_create_email',
                title: '主催者作成',
                icon: <Inventory />,
            },
        ]

        return createMenu(menu)
    }, [createMenu])

    const trustdockLink = React.useMemo(
        () => (
            <Tooltip title="TRUSTDOCK" placement="right" disableHoverListener={false} arrow>
                <ListItemButton
                    key="trustdock"
                    onClick={() =>
                        window.open('https://client.trustdock.io', '_blank', 'noopener noreferrer')
                    }
                    sx={{ justifyContent: 'center', paddingY: 0.5 }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                justifyContent: 'center',
                                minWidth: 36,
                                color: 'primary.contrastText',
                            }}
                        >
                            <Launch />
                        </ListItemIcon>
                        <ListItemText
                            primary="TRUSTDOCK"
                            primaryTypographyProps={{
                                noWrap: true,
                                fontSize: '8px',
                            }}
                        />
                    </Box>
                </ListItemButton>
            </Tooltip>
        ),
        []
    )

    const adminLink = React.useMemo(() => {
        const menu = [
            {
                key: 'bank_transfer_private',
                path: '/bank_transfer_private',
                title: '振込',
                icon: <AccountBalance />,
            },
            {
                key: 'payment_statement',
                path: '/payment_statement',
                title: '支払明細',
                icon: <Payment />,
            },
        ]

        return createMenu(menu)
    }, [createMenu])

    const univapayLink = React.useMemo(
        () => (
            <Tooltip title="UnivaPay" placement="right" disableHoverListener={false} arrow>
                <ListItemButton
                    key="univapay"
                    onClick={() =>
                        window.open(
                            'https://merchant.univapay.com/login?next=/dashboard/summary',
                            '_blank',
                            'noreferrer'
                        )
                    }
                    sx={{ justifyContent: 'center' }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                justifyContent: 'center',
                                minWidth: 36,
                                color: 'primary.contrastText',
                            }}
                        >
                            <OpenInNew />
                        </ListItemIcon>
                        <ListItemText
                            primary="UnivaPay"
                            primaryTypographyProps={{
                                noWrap: true,
                                fontSize: '8px',
                            }}
                        />
                    </Box>
                </ListItemButton>
            </Tooltip>
        ),
        []
    )

    const renderSider = React.useMemo(
        () => (
            <>
                {dashboard}
                {menuLink}

                <Divider sx={{ borderBottom: '1px solid lightgray' }} />

                {organizerLink}

                <Divider sx={{ borderBottom: '1px solid lightgray' }} />

                {adminLink}

                <Divider sx={{ borderBottom: '1px solid lightgray' }} />

                {trustdockLink}
                {univapayLink}

                <Divider sx={{ borderBottom: '1px solid lightgray' }} />

                {logout}
            </>
        ),
        [adminLink, dashboard, logout, menuLink, organizerLink, trustdockLink, univapayLink]
    )

    const drawer = React.useMemo(
        () => (
            <MuiList disablePadding sx={{ mt: 1, color: 'primary.contrastText' }}>
                {renderSider}
            </MuiList>
        ),
        [renderSider]
    )

    return (
        <>
            <Box
                sx={{
                    width: { xs: drawerWidth },
                    display: {
                        xs: 'none',
                        md: 'block',
                    },
                    transition: 'width 0.3s ease',
                }}
            />
            <Box
                component="nav"
                sx={{
                    position: 'fixed',
                    zIndex: 1101,
                    width: { sm: drawerWidth },
                    display: 'flex',
                }}
            >
                <Drawer
                    variant="permanent"
                    PaperProps={{ elevation: 1 }}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            bgcolor: 'secondary.main',
                            overflow: 'hidden',
                            transition: 'width 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                        },
                    }}
                    open
                >
                    <Box
                        sx={{
                            height: 64,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <RenderToTitle collapsed={true} />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowX: 'hidden',
                            overflowY: 'auto',
                        }}
                    >
                        {drawer}
                    </Box>
                </Drawer>
                <Box
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        position: 'fixed',
                        top: '64px',
                        left: '0px',
                        borderRadius: '0 6px 6px 0',
                        bgcolor: 'secondary.main',
                        zIndex: 1199,
                        width: '36px',
                    }}
                >
                    <IconButton sx={{ color: '#fff', width: '36px' }}>
                        <MenuRounded />
                    </IconButton>
                </Box>
            </Box>
        </>
    )
}
