import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import {
    AccountBalance,
    Group,
    Notifications,
    Person,
    Work,
    WorkHistory,
} from '@mui/icons-material'
import { CssBaseline, GlobalStyles } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { setZodErrorMap } from '@nilebridge/shared/src/zod/error_map'
import { useLocale } from '@nilebridge/web/src/app/commons/hooks/useLocale'
import { AuthBindings, Authenticated, Refine } from '@refinedev/core'
import dataProvider, { GraphQLClient } from '@refinedev/hasura'

import { notificationProvider, RefineSnackbarProvider, ThemedLayoutV2 } from '@refinedev/mui'

import routerProvider from '@refinedev/nextjs-router'
import { UnsavedChangesNotifier } from '@refinedev/nextjs-router/pages'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Sider } from 'components/sider'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { NextPage } from 'next'
import Head from 'next/head'
import Router from 'next/router'
import { SnackbarProvider, useSnackbar } from 'notistack'
import React from 'react'
import { RecoilRoot } from 'recoil'
import { baseThemeWithResponsiveFontSizes } from 'theme'
import { Title } from '../components/layout'
import type { AppProps } from 'next/app'
import '../styles/global.css'

export type ExtendedNextPage = NextPage & {
    noLayout?: boolean
}

type ExtendedAppProps = AppProps & {
    Component: ExtendedNextPage
}

export type PayLoad = JwtPayload & {
    'https://hasura.io/jwt/claims'?: {
        'x-hasura-allowed-roles': string[]
        'x-hasura-default-role': string
    }
}

export const client = new GraphQLClient(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    process.env.NEXT_PUBLIC_GRAPHQL_URI!
)

const queryClient = new QueryClient()

const gqlDataProvider = dataProvider(client)

const onRedirectCallback = (appState?: AppState) => {
    // Use Next.js's Router.replace method to replace the url
    Router.replace(appState?.returnTo || '/login')
}

const hasAdminRole = (jwt: PayLoad) => {
    return !!jwt['https://hasura.io/jwt/claims']?.['x-hasura-allowed-roles'].includes('admin')
}

const isTokenExpired = (jwt: PayLoad) => (jwt.exp ?? 0) * 1000 < new Date().getTime()

// Headerにundefinedを指定するとデフォルトのヘッダーが出てきてしまうため、明示的に空のJSXを代入するようにしている
const Header: React.FC = () => <></>

const RefineBody: React.FC<ExtendedAppProps> = ({ Component, pageProps }) => {
    const { isLoading, user, logout, getAccessTokenSilently, loginWithRedirect, isAuthenticated } =
        useAuth0()

    const renderComponent = React.useMemo(() => {
        if (Component.noLayout) {
            return <Component {...pageProps} />
        }

        if (!isLoading && !isAuthenticated) {
            loginWithRedirect()
            return <></>
        }

        return (
            <Authenticated key="web-admin">
                <ThemedLayoutV2 Sider={Sider} Title={Title} Header={Header}>
                    <Component {...pageProps} />
                </ThemedLayoutV2>
            </Authenticated>
        )
    }, [Component, isAuthenticated, isLoading, loginWithRedirect, pageProps])

    const { enqueueSnackbar } = useSnackbar()

    if (isLoading) {
        return <span>loading...</span>
    }

    const authProvider: AuthBindings = {
        login: async () => {
            return {
                success: true,
                redirectTo: '/',
            }
        },
        logout: async () => {
            logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })
            return {
                success: true,
                redirectTo: '/login',
            }
        },
        onError: async (_error) => {
            // if (error) {
            //     return {
            //         error: error,
            //         logout: true,
            //         redirectTo: '/login',
            //     }
            // }
            return {}
        },
        check: async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
                        audience: `https://${process.env.NEXT_PUBLIC_AUTH0_DOMAIN}/api/v2/`,
                        redirect_uri:
                            typeof window !== 'undefined' ? window.location.origin : undefined,
                    },
                })

                if (accessToken) {
                    const claims: PayLoad = jwtDecode(accessToken)

                    if (isTokenExpired(claims)) {
                        console.log('token expired')
                    }

                    if (!hasAdminRole(claims)) {
                        enqueueSnackbar('アクセス権限が含まれていません。', { variant: 'warning' })
                        return {
                            authenticated: false,
                            redirectTo: '/login',
                            logout: true,
                            error: new Error('Access permissions not included'),
                        }
                    }

                    client.setHeaders({
                        Authorization: `Bearer ${accessToken}`,
                        'x-hasura-role': hasAdminRole(claims) ? 'admin' : 'user',
                    })

                    return {
                        authenticated: true,
                    }
                }

                return {
                    authenticated: false,
                    error: new Error('Token not found'),
                    logout: true,
                    redirectTo: '/login',
                }
            } catch (error) {
                console.error({ error })
                return {
                    authenticated: false,
                }
            }
        },
        getPermissions: async () => null,
        getIdentity: async () => {
            if (user) {
                return Promise.resolve({
                    avatar: user.picture,
                    ...user,
                })
            }
            return null
        },
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Refine
                authProvider={authProvider}
                dataProvider={gqlDataProvider}
                routerProvider={routerProvider}
                resources={[
                    {
                        name: 'app_group',
                        list: '/app_group',
                        show: '/app_group/show/:id',
                        meta: {
                            icon: <Group />,
                        },
                    },
                    {
                        name: 'app_user',
                        list: '/app_user',
                        show: '/app_user/show/:id',
                        meta: {
                            icon: <Person />,
                        },
                    },
                    {
                        name: 'task',
                        list: '/task',
                        show: '/task/show/:id',
                        meta: {
                            icon: <Work />,
                        },
                    },
                    {
                        name: 'task_client_field',
                        list: '/task_client_field',
                        show: '/task_client_field/show/:id',
                        meta: {
                            icon: <WorkHistory />,
                        },
                    },
                    {
                        name: 'admin_notification',
                        list: '/admin_notification',
                        show: '/admin_notification/show/:id',
                        edit: '/admin_notification/edit/:id',
                        create: '/admin_notification/create',
                        meta: {
                            icon: <Notifications />,
                        },
                    },
                    {
                        name: 'app_group_contact_address_private',
                    },
                    {
                        name: 'app_group_contact_tel_private',
                    },
                    {
                        name: 'app_group_contact_email_private',
                    },
                    {
                        name: 'app_user_campaign_code_private',
                    },
                    {
                        name: 'app_group_belonging',
                    },
                    {
                        name: 'bank_transfer_private',
                        list: '/bank_transfer_private',
                        show: '/bank_transfer_private/show/:id',
                        meta: {
                            icon: <AccountBalance />,
                        },
                    },
                    {
                        name: 'payment_statement',
                        list: '/payment_statement',
                        show: '/payment_statement/show/:id',
                        meta: {
                            icon: <AccountBalance />,
                        },
                    },
                    {
                        name: 'payment_statement_app_group',
                        show: '/payment_statement_app_group/show/:id',
                    },
                    {
                        name: 'app_group_company',
                        list: '/company_verification',
                        show: '/company_verification/show/:id',
                    },
                    {
                        name: 'task_pesticide',
                    },
                    {
                        name: 'task_status_history',
                    },
                    {
                        name: 'task_check_item_message',
                    },
                    {
                        name: 'working_date',
                    },
                    {
                        name: 'chat',
                    },
                    {
                        name: 'chat_record',
                    },
                    {
                        name: 'organizer_group_can_create_email',
                        list: '/organizer_group_can_create_email',
                    },
                ]}
                notificationProvider={notificationProvider}
                options={{
                    syncWithLocation: true,
                    warnWhenUnsavedChanges: true,
                    reactQuery: {
                        devtoolConfig: false,
                    },
                }}
            >
                {renderComponent}
                <UnsavedChangesNotifier />
            </Refine>
        </QueryClientProvider>
    )
}

function App(props: AppProps) {
    const { locale } = useLocale()
    setZodErrorMap(locale)

    return (
        <Auth0Provider
            domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL ?? ''}
            clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? ''}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
            authorizationParams={{
                scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
                audience: `https://${process.env.NEXT_PUBLIC_AUTH0_DOMAIN}/api/v2/`,
                redirect_uri: typeof window !== 'undefined' ? window.location.origin : undefined,
            }}
        >
            <SnackbarProvider>
                <RecoilRoot>
                    <Head>
                        <title>ブリッジブースト 管理画面</title>
                    </Head>
                    <ThemeProvider theme={baseThemeWithResponsiveFontSizes}>
                        <CssBaseline />
                        <GlobalStyles styles={{ html: { WebkitFontSmoothing: 'auto' } }} />
                        {/*@ts-ignore*/}
                        <RefineSnackbarProvider>
                            <RefineBody {...props} />
                        </RefineSnackbarProvider>
                    </ThemeProvider>
                </RecoilRoot>
            </SnackbarProvider>
        </Auth0Provider>
    )
}

export default App
