import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { jaJP as coreJaJP } from '@mui/x-data-grid'
import { LightTheme } from '@refinedev/mui'

const baseTheme = createTheme(
    {
        ...LightTheme,
        typography: {
            // see: https://mui.com/customization/default-theme/
            h1: {
                fontSize: '2rem',
                lineHeight: 1.6,
                letterSpacing: '-0.035em',
            },
            h2: {
                fontSize: '1.5rem',
                lineHeight: 1.5,
                letterSpacing: '-0.03em',
            },
            h3: {
                fontSize: '1.25rem',
                lineHeight: 1.45,
                letterSpacing: '-0.025em',
            },
            h4: {
                fontSize: '1rem',
                lineHeight: 1.4,
                letterSpacing: '-0.02em',
            },
            h5: {
                // fontSize: '0.75rem',
                fontWeight: 'bold', // CardHeaderのTitleがh5のため
                lineHeight: 1.35,
                letterSpacing: '-0.02em',
            },
            h6: {
                fontSize: '0.5rem',
                lineHeight: 1.3,
                letterSpacing: '-0.02em',
            },
            body1: {
                lineHeight: 1.6,
                letterSpacing: '0.05em',
            },
            body2: {
                lineHeight: 1.5,
                letterSpacing: '0.05em',
            },
            subtitle1: {
                lineHeight: 1.6,
                letterSpacing: '0.05em',
            },
            subtitle2: {
                lineHeight: 1.5,
                letterSpacing: '0.05em',
            },
            caption: {
                fontSize: '0.85rem',
                lineHeight: 1.6,
                letterSpacing: '0.075em',
            },
            button: {
                fontSize: '0.85rem',
                // ボタンのラベルが大文字になるのを防ぐ
                textTransform: 'none',
            },
        },
        components: {
            MuiButton: {
                defaultProps: {
                    variant: 'contained',
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                        ...(ownerState.variant === 'outlined' && {
                            // MUI default で表示されていた外枠を表示する。
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                        }),
                    }),
                },
            },
            MuiFormControl: {
                defaultProps: {
                    size: 'small',
                    margin: 'normal',
                },
            },
            MuiSelect: {
                defaultProps: {
                    size: 'small',
                },
            },
            MuiTextField: {
                defaultProps: {
                    InputLabelProps: {
                        shrink: true,
                    },
                    size: 'small',
                    margin: 'normal',
                },
            },
            MuiListItemText: {
                defaultProps: {
                    primaryTypographyProps: {
                        fontWeight: 'bold',
                    },
                    secondaryTypographyProps: {
                        fontSize: 16,
                        sx: {
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                        },
                    },
                },
            },
            MuiPopover: {
                defaultProps: {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                },
            },
            MuiDialogTitle: {
                defaultProps: {
                    fontSize: '1.5em',
                },
            },
        },
    },
    coreJaJP
)

const baseThemeWithResponsiveFontSizes = responsiveFontSizes(baseTheme)

export { baseThemeWithResponsiveFontSizes }
